
import { defineComponent, ref } from "vue";
import Dropdown2 from "@/components/dropdown/Dropdown2.vue";

export default defineComponent({
  name: "kt-widget-3",
  components: {
    Dropdown2,
  },
  props: {
    widgetClasses: String,
  },
  setup() {
    const list = ref([
      {
        color: "primary",
        title: "Darkmode",
        text: "Prio #1",
      },
      {
        color: "primary",
        title: "Sortierung bei Tabellen",
        text: "Prio #2",
      },
      {
        color: "primary",
        title: "Nutzerprofil anpassen",
        text: "Prio #3",
      },
      {
        color: "warning",
        title: "einheitliche Lokalisierung",
        text: "Nice-to-have #1",
      },
      {
        color: "warning",
        title: "einheitliche Breadcrumbs",
        text: "Nice-to-have #2",
      },
    ]);

    return {
      list,
    };
  },
});
